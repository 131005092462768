<template>
  <el-main class="rh-medform">
    <form-fpostgradcreate :routes="fpgRoutes" :ismeddetail="true" />
  </el-main>
</template>
<script>
import FormFpostgradcreate from "@/components/rh/formulaires/FormFpostgradcreate";

export default {
  components: { "form-fpostgradcreate": FormFpostgradcreate },
  computed: {
    fpgRoutes() {
      return {
        toedit: {
          name: "med_fpostgrads_edit",
          params: {
            idmed: this.$route.params.idmed,
            idfpg: this.$store.state.fpg.fpostgradLastCreatedId,
          },
        },
        tolist: {
          name: "med_fpostgrads",
          params: {
            idmed: this.$route.params.idmed,
          },
        },
      };
    },
  },
};
</script>
